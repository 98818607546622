export default [
  {
    title: 'Pencarian',
    icon: '',
    route: 'pages-search',
    minAccess: 1,
    maxAccess: 99,
  },
  {
    title: 'Profil Pengguna',
    icon: '',
    route: 'pages-profile',
    minAccess: 1,
    maxAccess: 1001,
  },
]
